/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
 .disclaimer-content{
    font-family: "Poppins"!important;
 }
 .disclaimer-product-heading {
    color: #070F5A;
    text-align: left;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 700;
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto; /* For Safari */
    -ms-hyphens: auto; /* For IE10+ */
    hyphens: auto;
    white-space: normal; /* Ensure normal text wrapping */
    max-width: 100%; /* Ensure it fits within its container */
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .disclaimer-product-heading {
        font-size: 24px; /* Adjust the font size for mobile view */
    }
}

.evolutionai-disclaimer-logo{
    border-radius: 5px;
    border: 1px solid #070F5A;
    padding: 10px;
    background-color: #070F5A;
}
.stateLicenseInfo h2 {
    color: #070F5A;
    text-align: justify;
    font-family: 'Poppins';
    font-weight: 600;
}
.license_content p,
.stateLicenseInfo p,
.affilationInfo p,
.stateLicenseInfo a,
.disclaimer-product-states {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color: #070F5A;
    text-align: justify;
}
.disclaimer-product-states {
    font-weight: 700;
}

.stateLicenseInfo {
    margin-top: 1rem;
}

.swmcPhoneNumber {
    text-decoration: none;
}

.swmcPhoneNumber:hover {
    text-decoration: underline;
}

.swmcPhoneNumber {
    color: #070F5A !important;
}
.EAM-disclaimer-container{
    margin-top: 2rem;
}
@media screen and (max-width: 768px) {
    .EAM-disclaimer-container{
        margin-top: -1rem;
    }
    .stateLicenseInfo {
        margin-top: 1rem;
    }

    .license_content p,
    .stateLicenseInfo p,
    .stateLicenseInfo a,
    .affilationInfo p {
        font-size: 16px;
    }
}

@media screen  and (min-width: 576px) and (max-width:991.7px) {
    .EAM-disclaimer-container{
      margin-top: -4rem
    }
  }