.CommonBgComp-custom-component {
    position: relative;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -18rem;
    height: 300px;
    font-family: "Poppins"!important;
}
.CommonBgComp-text-overlay {
    color: #fff;
    text-align: center;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;  
    font-size: var(--fontSizeDesktop);
    white-space: nowrap;
}
@media screen and (max-width: 991.7px) {
    .CommonBgComp-custom-component {
        height: 300px;
    }
    .CommonBgComp-text-overlay {
        font-size: var(--fontSizeTablet);
    }
}
@media screen and (max-width: 575px) {
    .CommonBgComp-custom-component {
        height: 200px;
    }
    .CommonBgComp-text-overlay {
        font-size: var(--fontSizeMobile);
    }
}

@media screen  and (min-width: 992px) and (max-width: 1200px) {
    .CommonBgComp-custom-component {
        margin-top: -12.5rem;
        height: 200px;
    }
}



@media screen  and (max-width: 576px) {
    .CommonBgComp-custom-component {
        margin-top: -11.5rem;
    }
}

@media screen  and (min-width: 576px) and (max-width:991.7px) {
    .CommonBgComp-custom-component {
        margin-top: -14.5rem !important;
    }
}
