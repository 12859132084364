/* th {
    background: #eeebd4;
  } */
.accordion {
  --bs-accordion-btn-icon: url("https://staging-cdn.swmc.com/evolutionai/images/colored-arrow.svg") !important;
  --bs-accordion-btn-active-icon: url("https://staging-cdn.swmc.com/evolutionai/images/white-arrow.svg") !important;
  --bs-accordion-bg: #fff !important;
  --bs-accordion-btn-color: #070F5A !important;
  --bs-accordion-border-color: #070F5A !important;
  --bs-accordion-active-bg: #070F5A !important;
  --bs-accordion-active-color: #fff !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-color: #070F5A !important;
  --bs-accordion-btn-icon-transform: translateY(-0.5rem) rotate(180deg) !important;
  
}
.policy-policy-heading{
  color: #070F5A;
  text-align: center;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.tablebody .accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  text-align: justify;
  word-break: normal;
}

#termsnavbar .morganText {
  margin-top: -1rem;
}
.privacy-what-does{
  color: #070F5A;
  font-weight: 550;
  text-transform: uppercase;
}

.accordianhead {
  font-weight: 600;
}

#accordianhead h5{
  --bs-accordion-active-color: #ffffff !important;
}

@media screen and (min-width: 992px) {
  .NonAffiliatesnimg, .Affiliatesnimg{
    padding: 1rem;
  }
  .mainpage-footer {
    padding-top: 16rem;
    margin-top: -17rem;
    background-color: #0b1237;
  }

  th,
  td {
    padding: 8px;
    border: 2px solid #eeebd4;
    text-align: left;
  }

  .hero-image1 {
    background-image: url("https://resources.swmc.com/swmc-images/PrivacyPolicy/banner.jfif");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    color: #fff;

    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    margin: 6rem 20px 20px;
    padding: 20px;
    position: relative;

  }

  .factjsteble td {

    padding: 15px !important;
  }

  .whatdoes {
    font-weight: 500;
  }

  .hero-image {
    margin: 7rem 20px 20px !important;
  }


}

.starttavle {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.question {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 499px) {
  .mainpage-footer {
    margin-top: 5rem;
  }

  .terms-footer {
    margin-top: 6rem !important;
    padding-top: 0rem !important;
  }
}

@media only screen and (max-width: 991px) {
 
  .hero-image1 h1 {

    font-family: 'Lucky-Fellas';
  }

  .tablescroll {
    overflow-x: auto;
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #dddddd;
    text-align: justify;
  }

  /* .question td{
    width: 28%;
  } */





  .hero-image1 {
    background-image: url("https://resources.swmc.com/swmc-images/PrivacyPolicy/banner.jfif");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    color: #fff;

    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    margin: 6rem 20px 20px;
    padding: 20px;
    position: relative;

  }

  .tablefact {
    overflow-x: auto;
  }
}

.table-container {
  overflow-x: auto;
}

.questioncelli {
  margin-bottom: 1rem;
}

#terms-wrapper1 a {
  text-decoration: none;
}

#terms-wrapper1 a:hover {
  text-decoration: underline;
}


/* .question  tbody > tr > td{
  padding:15px !important;
  vertical-align: middle;
} */
.question {
  width: 100%;
  /* Set the width to 100% */
}

@media screen and (min-width: 320px) and (max-width:414px) {
  .question {
    width: 200%;
  }

  .question td {


    font-size: 13px !important;
    text-align: justify !important;
  }

  .starttavle td {
    font-size: 13px !important;
    text-align: justify !important;
  }

  .question th {

    padding: 15px !important;
    font-size: 13px !important;
    text-align: justify !important;
  }

  .starttavle {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 200%;
    overflow: auto;
  }


}

@media screen  and (max-width: 576px) {
    .policy-policy-heading{
      margin-top: 0rem;
      margin-bottom: 2rem;
    }
}

@media screen  and (min-width: 576px) and (max-width:991.7px) {
  .policy-policy-heading{
    margin-top: -3rem;
    margin-bottom: 2rem;
  }
}
