#rigvedQuotesContainer {
  color: #000;
  border: 1px solid #000;
  border-radius: 15px;
  background-color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  position: absolute;
  /* top: 190%;
  right: 0.5%; */
  font-size: 15px;
  width: 300px;
  z-index: 1000;
  display: block;
  margin-left: auto;
  margin-right: auto;
  left: 39%;
}
@media only screen and (min-width: 320px) and (max-width: 991.7px) {
    #rigvedQuotesContainer {
      font-size: 13px;
      width: 280px;
      left: 8%;
    }
  }
  @media only screen and (min-width: 370px) and (max-width: 420px) {
    #rigvedQuotesContainer {
      left: 13%;
    }
  }

  @media only screen and (min-width: 410px) and (max-width: 470px) {
    #rigvedQuotesContainer {
      left: 18%;
    }
  }

  @media only screen and (min-width: 471px) and (max-width: 576px) {
    #rigvedQuotesContainer {
      left: 22%;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767.7px) {
    #rigvedQuotesContainer {
      left: 30%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    #rigvedQuotesContainer {
      left: 32%;
    }
  }

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  #rigvedQuotesContainer {
    left: 35%;
  }
}

@media only screen and (min-width: 1600px)  {
  #rigvedQuotesContainer {
    left: 41%;
  }
}

@media only screen and (min-width: 2000px)  {
  #rigvedQuotesContainer {
    left: 43%;
  }
}
@media only screen and (min-width: 2449px)  {
  #rigvedQuotesContainer {
    left: 44%;
  }
}