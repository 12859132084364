/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */

#termsAndConditionsContainer .angelaiDisclaimer {
    text-align: justify;
    word-wrap: break-word;
    color: #070F5A;
    font-family: Poppins;
    font-weight: 400;
    margin-top: 0rem;
}
#termsAndConditionsContainer .angelaiDisclaimer a{
    color: #070F5A;
}

#termsAndConditionsContainer .section_terms{
    background: #FDF3F0;
    padding: 20px;
    margin-bottom: 10px;
    text-align: justify;color: var(--wf__black_1, #000);
    font-family: Poppins;
}
#termsAndConditionsContainer .section_terms a {
    text-align: justify;color: var(--wf__black_1, #000);
}

#termsAndConditionsContainer .terms_content h3 {
    color: var(--wf__black_1, #070F5A);
    text-align: justify;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
}
#termsAndConditionsContainer .terms_content p {
    text-align: justify;
    word-wrap: break-word;
    color: var(--wf__black_1, #000);
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
}
#termsAndConditionsContainer .terms_content a {
    color: var(--wf__black_1, #000);
}

@media (max-device-width : 560px) {
    #termsAndConditionsContainer .angelaiDisclaimer p {
        margin-bottom: 20px !important;
    }

}

@media (max-width:1024px) {
    #termsAndConditionsContainer .terms_content {
        margin-bottom: 10px;
    }

    #termsAndConditionsContainer .terms_content h3 {
        margin-bottom: 5px;
    }
}

@media screen  and (min-width: 576px) and (max-width:991.7px) {
    #termsAndConditionsContainer{
      margin-top: -4rem
    }
  }

@media screen  and (min-width: 320px) and (max-width:576px) {
    #termsAndConditionsContainer{
      margin-top: -1rem
    }
  }