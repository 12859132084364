.bannerHeader {
    font-weight: 600;
    color: #3074b7;
    line-height: 48px;
    font-size: 44px;
    font-family: 'Graphik Bold' !important;
  }
  
  .blackBannerHeader {
    font-weight: 600;
    color: black;
    line-height: 48px;
    font-size: 44px;
    font-family: 'Graphik Bold' !important;
  }
  
  .bannerPara {
    font-family: 'Poppins' !important;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  
  .bannerButton {
    font-weight: 100;
    font-family: "Graphik Medium";
    letter-spacing: 0.3px;
    background-color: #3074b7;
    color: #fff;
    padding: 14px 33px !important;
    border-radius: 30px !important;
    border: 2px solid #3074b7 !important;
    text-decoration: none !important;
    display: inline-block;
    transition: all .3s;
    color: #ffffff;
    margin-right: 20px;
    width: 250px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
  }
  
  .whiteBannerButton {
    background-color: white !important;
    color: #3074b7 !important;
  }
  
  .parallaxDiv {
    background-color: #3f3c3c;
    padding-top: 70px;
    padding-bottom: 90px;
    text-align: center;
    min-height: 100vh;
    position: relative;
  }
  
  .parallaxDiv::after {
    background-image: url('https://https://d2rj1mvpo4p9ef.cloudfront.net/lowrates-development-angular/background-img.png');
    content: '';
    width: 33%;
    height: 236px;
    position: absolute;
    background-repeat: no-repeat;
    /* margin-top: -142px; */
    background-size: 100%;
    right: 0;
    bottom: 0;
  }
  
  .loanContainer {
    padding-top: 25px;
    text-align: center;
  }
  
  .loanDivText {
    display: inline-block;
    width: 45%;
    text-align: left;
  }
  
  .loanDivText h2 {
    font-size: 36px;
    font-family: "Graphik Bold";
    font-weight: 100;
    margin: 0;
  }
  
  .rateDiv {
    background-image: url('https://d2rj1mvpo4p9ef.cloudfront.net/lowrates-development-angular/man_03.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 60%;
    height: 497px;
  }
  
  .loanDivText p {
    font-size: 16px;
    font-family: "Poppins";
    line-height: 28px;
    padding-right: 146px;
  }
  
  .loanDivRate {
    background-color: #ffffff;
    text-align: center;
    border: 2px solid #444444;
    width: 250px;
    padding: 15px 30px;
    /* box-shadow: 2px 3px 19px -1px #80808061; */
    border-radius: 4px;
  }
  
  .loanDivRate h5 {
    margin-top: 10px;
    line-height: 27px;
    margin-bottom: 5px;
    font-weight: 600;
    letter-spacing: 1px
  }
  
  .loanDivRate ul {
    padding: 0;
  }
  
  .loanDivRate li {
    list-style: none;
    background-color: #8fb4d8;
    margin-bottom: 10px;
    padding: 13px 0;
    color: #fff;
    border-radius: 3px;
    border: 2px solid #8fb4d8;
    transition: all .3s;
    cursor: pointer;
    font-size: 14px;
  }
  
  .loanDivRate li a {
    color: white;
    font-family: 'Poppins';
    text-decoration: none;
  }
  
  .headerTop {
    margin-top: 70px;
  }
  
  .formContainer {
    height: auto;
    margin-top: -0.5rem;
    margin-bottom: -1rem;
    position: relative;
    /* background-color: #000!important; */
  }
  
  .leftBGPattern {
    position: absolute;
    left: 0;
    bottom: 1%;
    height: 100%;
    z-index: -1;
  }
  
  .rightBGPattern {
    position: absolute;
    right: 0;
    bottom: 1%;
    height: 100%;
    z-index: -1;
  }
  
  .leftTopBlueDots {
    position: absolute;
    left: -1.5%;
    top: 5%;
    width: 3%;
    z-index: -1;
  }
  
  .leftBlueDots {
    position: absolute;
    left: 5%;
    top: 20%;
    width: 4%;
    z-index: -1;
  }
  
  .rightBlueDots {
    position: absolute;
    right: 2%;
    bottom: 15%;
    width: 4%;
    z-index: -1;
  }

  #productResult .nav-tabs{
    width: 90% !important;
  }

  
  @media(max-width:1200px) {
    .parallaxDiv {
      min-height: auto;
    }
  }
  
  @media(max-width:767px) {
    .loanDivText {
      width: 100%;
    }
   
  
    .loanDivText p {
      padding-right: 0px;
    }
  
    .rateDiv {
      background-position: 150% 0%;
    }
  
    .headerTop {
      margin-top: 00px;
    }
  }
  
  @media(min-width:600px)and (max-width:768px) {
    .bannerHeader {
  
      font-size: 27px;
      font-family: 'Graphik Bold' !important;
    }
  
    .blackBannerHeader {
  
      font-size: 30px;
      font-family: 'Graphik Bold' !important;
    }
  
    .headerTop {
      margin-top: 00px;
    }
  
    .parallaxDiv {
      min-height: auto;
    }
  
    .loanDivText {
      display: inline-table;
    }
  
    .rateDiv {
      background-image: none;
    }
  
    .loanDivText p {
      padding-right: 0px;
    }
  }
  
  .phonetext {
    text-align: center;
    color: #00C2FF;
    margin-top: 1rem;
    font-weight: 600;
  }
  
  .phonetext a {
    color: #00C2FF;
  }
  
  .leftBannerDiv {
    /* margin-left: -2rem; */
    background: #ffffff;
    box-shadow: 0px 3.30px 20px rgba(0,0 , 0, 0.20) !important;;
  }
  
  .rightBannerDiv {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .rightBannerDiv .rightImgBox {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 1rem;
  }
  
  @media (min-width: 992px) {
    .rightBannerDiv {
      margin-bottom: 2rem;
    }
    .phonetext {
      margin-bottom: 1rem;
    }
    
  
    .phonetext a {
      color: #00C2FF;
      margin-bottom: 2rem;
    }
    
  }
  
  @media (max-width: 991px) {
    .form-body-col{
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
    .rightBannerDiv {
      margin: 0 0 2rem 0;
      width: 60%;
    }
    .multi-step{
      padding-bottom: 3rem !important;
    }
  }
 
  
  @media (min-width: 768px) and (max-width: 992px) {
   
  
    .rightBannerDiv {
      width: 50% !important;
      margin-bottom: 0;
      margin: 0rem 0 0 0;
    }
    .form-body-col{
        
        margin-top: -2rem;
      }
  
    .leftBannerDiv {
      margin-left: 0rem !important;
    }
  
    .rightBannerDiv {
      margin-left: 0rem !important;
      margin-bottom: 2rem !important;
    }
  
    .phonetext {
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
    }
  }
  
  
  
  @media (max-width: 550px) {
    .rightBannerDiv .rightImgBox {
      flex-direction: column;
      align-items: center;
    }
  
    .rightBannerDiv p {
      margin-bottom: 20px !important;
    }
  }
  
  @media (min-width: 2100px) {
    .rightBannerDiv {
      margin: -5rem 0 0 0;
    }
  }
  
  
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .rightBannerDiv {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: -0.5rem 0 0 0;
    }
  }
  
  @media (min-width: 2000px) and (max-width: 2560px) {
    .rightBannerDiv {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 0 0;
      margin-left: 2rem;
    }
  
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    .leftBannerDiv {
      margin-left: 0rem !important;
    }
  
    .rightBannerDiv {
      margin-left: 0rem !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 767.7px) {
    .formContainer {
      height: auto;
    }
  
    /* .leftBannerDiv {
      margin-left: 0rem !important;
    } */
  
    .rightBannerDiv {
      margin-left: 0rem !important;
      width: 100% !important;
    }
  
  }
  
  @media (min-width: 768px) {
    .imagewrappermobile {
      display: none;
    }
  }
  
  @media (max-width: 767.8px) {
    .imagewrappermobile {
      height: 100px;
      margin: -8rem 0;
      width: 100%;
      margin-left: 0rem;
    }
 
  }
  
  @media (min-width: 425px) and (max-width: 500px) {
    .imagewrappermobile {
      margin: 3rem 0;
      margin-bottom: -8rem !important;
    }
  }
  
  @media (min-width: 501px) and (max-width: 575px) {
    .imagewrappermobile {
      margin: 3rem 0;
      margin-bottom: -8rem !important;
    }
  }
  
  @media (min-width: 576px) and (max-width: 767px) {
    .imagewrappermobile {
      margin: 3rem 0;
      margin-bottom: -8rem !important;
    }
  
  }
  
  @media (min-width: 360px) and (max-width: 449.7px) {
    .imagewrappermobile {
      margin-top: 3rem !important;
    }
  
    .phonetext {
      margin-top: 2rem !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 349.7px) {
    .imagewrappermobile {
      margin-top: 21rem !important;
    }
  
    .phonetext {
      margin-top: 2rem !important;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.7px) {
    .formContainer {
      height: auto;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1100px) {
    .formContainer {
      height: auto;
    }
  }
  
   @media (min-width: 2000px) and (max-width: 2560px) {
    .rightBannerDiv .rightImgBox {
      margin-bottom: 2rem !important;
    }
  }
  
  
  @media (min-width: 992px) and (max-width:1350.7px) {
    .rightBannerDiv .rightImgBox {
      margin-bottom: 2rem;
    }
    .phonetext {
      margin-bottom: 2rem;
    }
  }

  .overRideTransition {
    color: #23527c;
  }
  
  #ratesWrapper {
    position: relative;
    display: flex;
    /* background-color: #fff; */
    /* background-image: url('https://staging-cdn.swmc.com/lowrates/bgLRRatesdesktop.webp');
    background-repeat: no-repeat;
    background-size: cover; */
    background-position: bottom;
    margin-top: -4.5rem;
  }
  
  #ratesWrapper #headerFooterbg {
    position: absolute;
    z-index: -1;
    bottom: -2%;
    height: 100px;
    width: 50%;
    background-image: url('https://resources.swmc.com/swmc-images/Rectangle 13.png');
    background-size: cover;
    background-position: bottom;
  }
  
  .btn-cq {
    background: #DB3700 !important;
    color: #fff;
    display: block;
    border: 0;
    padding: 10px;
    margin: 10px auto;
    text-align: center;
    padding: .5rem 1.5rem !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    border: none !important;
    border-radius: 0.5rem !important;
  }
  
  .btn-cq:hover,
  .btn-cq:focus {
    background: linear-gradient(180deg, #00abe4d5 0%, #083f88d2 100%) !important;
  }
  
  .longCyanButton {
    background-color: #0053a6 !important;
    font-size: 20px;
    color: #fff;
    margin: 10px auto;
    font-weight: 600;
    border-radius: 5px
  }
  
  .greyButton {
    background-color: #737373;
    color: #fff;
    display: block;
    font-size: 20px;
    width: 100%;
    border: 0;
    max-width: 200px;
    padding: 10px;
    margin: 10px auto;
    text-align: center;
    font-weight: 600;
    border-radius: 5px
  }
  
  .cyanButton:hover,
  .greyButton:hover,
  .longCyanButton:hover {
    background-color: #023368 !important;
    color: #fff
  }
  

  
  .h5,
  h5 {
    font-size: 1.15rem;
  }
  
  .textBlue {
    color: #00AAE4;
  }
  
  h4.fontSizeRateAprText {
    color: #083F88;
  }
  
  h2.reducefontSize,
  h3.lbl-Apply {
    color: #0e3e9b;
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
  
  .btnApplyNow .textBlue.Poppins-bold {
    font-weight: 700;
  }
  
  .btnApplyNow h2.reducefontSize {
    font-weight: 500;
    /* color: #083F88; */
    font-size: 1.5rem !important;
  }
  

  @media (min-width: 1200px) and (max-width: 1400px) {
    .btnApplyNow h2.reducefontSize {
      font-size: 1.23rem !important;
    }
  
    .h5,
    h5 {
      font-size: 19px !important;
    }
  
    #ratesWrapper {
      height: auto !important;
    }
  }
  
  .lbl-Apply {
    border-top: 2px #D9D9D9 solid !important;
  }
  
  .textBlue td {
    color: #0e3e9b;
  }
  
  .applyNowWrapper .modal-content {
    margin-top: 4rem;
  }
  
  .btnApplyNow {
    width: 100%;
    color: #0e3e9b;
    padding: 1rem 1rem 0.5rem !important;
    background: #fff !important;
    transition: .6s !important;
    border-color: #fff !important;
    border-radius: 1rem !important;
    box-shadow: 0px 3.30px 20px rgba(0,0 , 0, 0.20) !important;
  }
  
  
  .btnApplyNow.btn-primary:hover,
  .btnApplyNow.btn-primary:focus {
    background-color: #3074b7 !important;
    color: #fff !important;
    border-color: #3074b7 !important;
  }


  
  .applyNowWrapper .modal-header {
    background-color: #fff !important;
    border-bottom: none !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: 0;
  }
  
  
  
  .borrowerLeadGeneration .form-check-label {
    padding: 0 1.5rem 0 0 !important;
  }
  
  .borrowerLeadGeneration .btn-start {
    background-color: #083f88;
    border-color: #083f88;
    color: #ffffff !important;
  }
  
  .borrowerLeadGeneration .btn-start:hover {
    background-color: #2ba8e0;
    border-color: #2ba8e0;
  }
  
  .backToHome,
  .multi-step .footer-buttons .btn-primary {
    font-size: 20px !important;
    font-weight: 600 !important;
    width: auto;
    background: transparent !important;
    color: #054d9a !important;
    border: 2px solid #fff !important;
    margin: 0 16px 0 0 !important;
    padding: 9px 20px !important;
    line-height: 14px !important;
    border-radius: 2px !important;
    transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    -webkit-border-radius: 2px;
  }
  
  
  .multi-step .footer-buttons #prev-button,
  .multi-step .footer-buttons #next-button {
    border-radius: 0px !important;
    height: unset !important;
    top: unset !important;
    transform: translateY(0) !important;
  }
  
  
  .ratesSection .nav-tabs {
    border-bottom: none;
  }
  
  .tab-content>.active {
    margin: 0 -2rem;
  }
  
  .nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #6c757d !important;
    font-weight: bolder;
  }
  
  #loan-purpose-tabs-tabpane-purchase {
    background-image: url('https://staging-cdn.swmc.com/angelai-life/rateheadingbackground.webp') !important;
    background-repeat: no-repeat;
    background-size: 78% 2.4%;
    width: auto;
    background-position: center 16%;
    position: relative;
    padding: 10px;
  }
  
  #loan-purpose-tabs-tabpane-refinance {
    background-image: url('https://staging-cdn.swmc.com/angelai-life/rateheadingbackground.webp') !important;
    background-repeat: no-repeat;
    background-size: 78% 2.4%;
    background-position: center 16%;
    position: relative;
    padding: 10px;
  }
  
  #loan-purpose-tabs-tabpane-homeEquity {
    background-image: url('https://staging-cdn.swmc.com/angelai-life/rateheadingbackground.webp') !important;
    background-repeat: no-repeat;
    background-size: 82% 2.4%;
    background-position: center 0%;
    position: relative;
    padding: 10px;
  }
  
  .ratesSection .nav-tabs .nav-link {
    width: 160px;
    color: #00AAE4 !important;
    padding: 0rem 0.5rem;
    border-radius: 0;
    margin-right: .25rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center !important;
    /* background: rgba(0, 170, 228, 0.60) !important; */
  }
  
  @media (min-width: 992px) and (max-width: 1200px) {
    #ratesWrapper {
      height: auto !important;
    }
  
    .textBlue h4 {
      font-size: 22px;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 140px !important;
      margin-right: 0rem !important;
      font-size: 0.9rem !important;
    }
  
    .btnApplyNow h2.reducefontSize {
      font-size: 0.95rem !important;
    }
  
    .btnApplyNow .textBlue.Poppins-bold {
      font-size: 16px !important;
    }
  
    h2.reducefontSize,
    h3.lbl-Apply {
      font-size: 1.2rem !important;
    }
  
    .mainBody h6,
    #home-products-tabs-tabpane-va h4 {
      font-size: 0.6rem !important;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 86% 2.4% !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 86% 2.4% !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 86% 2.4% !important;
    }
  
    .h5,
    h5 {
      font-size: 0.85rem;
    }
  }
  
  .ratesSection .nav-tabs .nav-link:focus {
    outline-color: none !important;
  }
  
  .ratesSection .nav-tabs button.nav-link.active:not(#loan-purpose-tabs-tab-purchase, #loan-purpose-tabs-tab-refinance, #loan-purpose-tabs-tab-homeEquity) {
    border-color: initial;
    color: #083F88 !important;
    font-weight: 600;
    background-color: initial !important;
    border: none !important;
    background-image: url('https://staging-cdn.swmc.com/angelai-life/ratesheadinghead.webp') !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index: 2;
  }
  
  #loan-purpose-tabs-tab-purchase.active {
    border-color: initial;
    color: #083F88 !important;
    border: none !important;
    background-color: initial !important;
    /* border-right: 1px solid #083F88 !important; */
  }
  
  #loan-purpose-tabs-tab-refinance.active {
    border-color: initial;
    color: #083F88 !important;
    border: none !important;
    background-color: initial !important;
    /* border-left: 1px solid #083F88 !important; */
  }
  
  #loan-purpose-tabs-tab-homeEquity.active {
    border-color: initial;
    color: #083F88 !important;
    border: none !important;
    background-color: initial !important;
    border-left: 1px solid #083F88 !important;
    border-right: 1px solid #083F88 !important;
  }
  


  .rate-title-bg-color.rate-title-bg-color {
    margin-bottom: 1rem;
    box-shadow: none !important;
  }
  
  .mainBody h6,
  #home-products-tabs-tabpane-va h4 {
    color: #083F88;
    font-size: 0.8rem;
  }
  
  .ratesTitle {
    width: 100%;
  }
  
  .Poppins-med hr {
    color: transparent;
  }
  
  .text-white {
    font-size: 2rem;
    color: #083F88 !important;
    font-weight: 600 !important;
  }
  
  #todayRatesTitle {
    margin-top: 2rem !important;
  }
  
  .btnApplyNow .textBlue.Poppins-bold {
    font-size: 20px;
  }
  
  @media (min-width: 1400px) {

    .step-progress h5 {
      font-size: 24px !important;
    }
  #loan-purpose-tabs-tabpane-purchase {
    background-size: 68% 2.4% !important;
    background-position: center 14.5% !important;
  }

  #loan-purpose-tabs-tabpane-refinance {
    background-size: 68% 2.4% !important;
    background-position: center 14.5% !important;
  }

  #loan-purpose-tabs-tabpane-homeEquity {
    background-size: 68% 2.4% !important;
  }
}


  
  
  @media (max-width: 767.7px) {
    #ratesWrapper {
      height: auto;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 9px !important;
    }
  
    .tab-content>.active {
      margin: 0 -1rem;
    }
  
    #todayRatesTitle {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  
    .btnApplyNow {
      width: 85% !important;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 85% 1% !important;
      background-position: center 8% !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 85% 1% !important;
      background-position: center 8% !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 85% 1% !important;
      background-position: center 9% !important;
    }
  
    .mainBody h6 {
      font-size: 0.75rem !important;
    }
    #loan-purpose-tabs-tab-purchase.active, #loan-purpose-tabs-tab-purchase {
      border-right: 1px solid #083F88 !important;
    }
    
    /* #loan-purpose-tabs-tab-refinance {
      border-right: 1px solid #083F88 !important;
    } */
    
    #loan-purpose-tabs-tab-homeEquity, #loan-purpose-tabs-tab-homeEquity.active{
      border-left: none !important;
      border-right: 1px solid #083F88 !important;

    }
  
  }
  
  @media (max-width: 375.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 0px !important;
      padding: 0px 10px !important;
    }
  }
  
  @media (min-width: 376px) and (max-width: 389.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      font-size: 0.8rem;
    }
  }
  
  @media (min-width: 320px) and (max-width: 350.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 0px !important;
      padding: 0px 8px !important;
      font-size: 0.8rem;
    }
    .leftBannerDiv{
      margin-top: -67rem !important; 
  }
  #loan-purpose-tabs-tabpane-purchase {
    background-position: center 6.5%  !important;
  }
  
  #loan-purpose-tabs-tabpane-refinance {
    background-position: center 6.5% !important;
  }
  
  #loan-purpose-tabs-tabpane-homeEquity {
    background-position: center 6.5% !important;
  }
  }
  
  @media (min-width: 768px) and (max-width: 991.7px) {
  
    #ratesWrapper {
      height: auto !important;
    }
  
    #todayRatesTitle {
      margin-top: 1rem !important;
    }
  
    .tab-content .poppins-black {
      display: contents !important;
    }
  
    .textBlue {
      font-size: 18px !important;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      font-size: 0.75rem !important;
      margin-right: 1rem;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 80% 2.4%;
      background-position: center 15%;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 80% 2.4%;
      background-position: center 15%;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 90% 2.4%;
      background-position: center 0.5%;
    }
  
  
    .btnApplyNow {
      width: 75%;
      margin-top: 1rem;
    }
  
    .tab-content>.active {
      margin: 0 -2rem !important;
    }
  
    .btnApplyNow .textBlue.Poppins-bold {
      font-size: 15px !important;
    }
  
    .btnApplyNow h2.reducefontSize {
      font-weight: 900;
      font-size: 0.85rem !important;
    }
  
    .fontSizeRateAprText {
      font-size: 0.6rem !important;
    }
  
    h2.reducefontSize,
    h3.lbl-Apply {
      font-size: 0.7rem !important;
    }
  
    .btn-cq {
      padding: 10px 10px !important;
      /* margin-right: -4.5rem; */
      font-size: 0.75rem !important;
    }
  
    .mainBody h6,
    #home-products-tabs-tabpane-va h4 {
      color:#083F88;
      font-size: 10px;
    }
  
    .h5,
    h5 {
      font-size: 8px !important;
    }
  }
   
  .step-progress h2 {
  
    color: #083F88 !important;
  }
  
  .step-progress h4 {
    color: #083F88 !important;
    font-size: 16px;
  }

  .productResult h2 {
    color: #083f88 !important;
  }
  
  .productResult h4 {
    color: #00aae4 !important;
  }

  .btnApplyNow.btn-primary:hover h2,
  .btnApplyNow.btn-primary:focus h2, .btnApplyNow.btn-primary:focus h4{
    /* background-color: #3074b7 !important; */
    color: #fff !important;
    border-color: #3074b7 !important;
  }
  .btnApplyNow.btn-primary:hover h4{
    color: #fff !important;
    border-color: #3074b7 !important;
  }
  
  .radImg {
    transform: 0 !important;
  }
  
  .radImg2 {
    max-width: 90px !important;
    top: 15% !important;
  }
  
  .step-progress label {
    width: 90px !important;
  }
  
  label[for="sfrProperty"].form-check-label,
  label[for="condoProperty"].form-check-label,
  label[for="unitsProperties"].form-check-label,
  label[for="manuProperty"].form-check-label {
    font-size: 8px !important;
  }
  
  .multi-step .pb-md-0 {
    margin-left: -3rem;
  }
  
  .form-check {
    padding-left: 0em;
  }
  
  .step-progress h5 {
    color: #083F88;
    font-size: 18px;
  }
  
  button.backToHome {
    background:#083F88 !important;
    margin-top: 20px !important;
    margin-left: -5px !important;
  }
  
  .multi-step .footer-buttons #next-button,
  .multi-step .footer-buttons #prev-button,
  .backToHome {
    background:#054d9a  !important;
    color:#fff  !important;
  }
  
  .multi-step .footer-buttons #next-button:hover,
  .multi-step .footer-buttons #prev-button:hover,
  .backToHome {
    background: #054d9a !important;
    color: #fff !important;
  }
  
  .step-progress .form-label {
    margin-top: -7rem;
  }
  
  th.textBlue.poppins-med h5 {
    color: #083F88;
    font-size: 16px !important;
  }
  
  
  @media(min-width: 992px)and (max-width:1199.7px) {
    .radImg2 {
      max-width: 70px !important;
      top: 20% !important;
    }
  
    .multi-step .footer-buttons #next-button {
      position: absolute;
      right: -16px !important;
    }
  
    .step-progress h2 {
      font-size: 1rem;
    }
  
  
  
    input[placeholder="Property Zip Code"]::placeholder {
      font-size: 10px !important;
    }
  
    .step-progress label {
      width: 80px !important;
    }
    .step-progress input[type='radio']:checked + label{
      font-size: 9px !important;
    }
  
    .step-progress .form-select {
      font-size: 11px !important;
    }
  
    .step-progress h4 {
      font-size: 11px;
    }
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 80% 2.4%;
      background-position: center 15%;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 80% 2.4%;
      background-position: center 15%;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 90% 2.4%;
      background-position: center 0.5%;
    }
  }

  @media (min-width: 351px) and (max-width: 362.7px) {
    .form-body-col{
      margin-top: -1.5rem;
    }
  }
  
  @media(min-width: 1200px)and (max-width:1399.7px) {
    .step-progress .form-select {
      font-size: 13px !important;
    }
  
    .multi-step .footer-buttons #next-button {
      right: -20px !important;
    }
  
    input[placeholder="Property Zip Code"]::placeholder {
      font-size: 13px !important;
    }
  
    .step-progress h2 {
      font-size: 26px;
    }
  
    .step-progress h4 {
      font-size: 14px;
    }
  
    .radImg2 {
      max-width: 80px !important;
      top: 20% !important;
    }
  
    .step-progress label {
      width: 89px !important;
    }
  
    label[for="sfrProperty"].form-check-label,
    label[for="condoProperty"].form-check-label,
    label[for="unitsProperties"].form-check-label,
    label[for="manuProperty"].form-check-label {
      font-size: 8px !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 991.7px) {
    .multi-step .footer-buttons {
      margin-top: 10px !important;
    }
  
    .step-progress .form-label {
      margin-bottom: -5.5rem !important;
    }
    .leftBannerDiv{
        margin-top: -58.75rem;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
        border-radius: 10px;
       
    }
  }
  
  @media screen and (max-width: 475px) {
    .formBody .btn-submit {
      width: 110px !important;
    }
  
    .applyNowWrapper .form-check {
      margin-left: 1rem !important;
    }
  
    .borrowerLeadGeneration .btnAdjustDiv {
      left: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      display: block !important;
    }
  
    .borrowerLeadGeneration .backBtnDiv {
      z-index: 10 !important;
    }
  
    .borrowerLeadGeneration .btn-success {
      left: 40% !important;
      position: relative !important;
    }
    .applyNowWrapper .btn-submit{
      padding: 7px 18px !important;
    }
  
    .borrowerLeadGeneration .consent-font {
      color: black;
      font-size: 12px;
    }
  }
  

  
  
  @media screen and (max-width: 767.7px) {
    #loan-purpose-tabs-tabpane-purchase {
      /* background-image: none !important; */
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      /* background-image: none !important; */
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-image: none !important;
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }

    #loan-purpose-tabs-tabpane-purchase {
      background-size: 80% 0% !important;
      background-position: center 15%;
      padding: 7px !important;
    }
  
    #products-purchase-tabs {
      position: relative;
      border-bottom: 1.7px solid #083F88;
      width: 85%;
      
  }
  
  #products-purchase-tabs::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 5px; 
      height: 5px;
      border-radius: 50%;
      background: #083F88;
  }
  
  #products-purchase-tabs::before {
      content: "";
      position: absolute;
      bottom: -3px;
      right: 0;
      width: 5px; 
      height: 5px;
      border-radius: 50%;
      background: #083F88;
  }
  
  
  }
  
  @media (min-width: 992px) and (max-width:1199.7px) {
    .text-danger {
      font-size: 11px !important;
    }
  }
  
  @media (min-width: 1200px) and (max-width:1400.7px) {
    .text-danger {
      font-size: 13px !important;
    }
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 80% 2.4%;
      background-position: center 14.5%;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 80% 2.4%;
      background-position: center 14.5%;
    }


  }
  
  @media (min-width: 1400px) and (max-width:1650.7px) {
    .text-danger {
      font-size: 15px !important;
    }
  }
  
  @media screen and (min-width: 767.7px) {
    .checkIConsent {
      padding-left: 1rem !important;
    }
  }

  .form-body-col{
    background-color: #074290;
  }
 

  @media screen and (min-width: 992px) {
    .body-row{
        margin-top: -35rem;
    }

    .checkIConsent {
      padding-left: 2rem !important;
    }
    #loan-purpose-tabs-tab-homeEquity{
      border-left: 1px solid #083F88 !important;
      border-right: 1px solid #083F88 !important;
    }
    .leftBannerDiv{
        padding-right: 1rem;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-bottom: 1rem;
    }
    .form-body-col{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
  }
    

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .leftBannerDiv, .form-body-col{
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    #loan-purpose-tabs-tab-homeEquity, #loan-purpose-tabs-tab-homeEquity.active{
      border-left: 1px solid #083F88 !important;
      border-right: 1px solid #083F88 !important;

    }
    .leftBannerDiv{
        margin-top: -39rem;
    }

    .form-body-col{
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
    .body-row{
        margin-top: -2rem;
    }
  }

  @media screen  and (min-width: 992px) and (max-width: 1199px){
    .body-row{
      margin-top: -30rem;
  }
  }

 


 
 