/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
#texasDisclosureContainer{
   
    color: #070F5A;
}

#texasDisclosureContainer h4{
    font-weight: 700;
    
}
#texasDisclosureContainer p {
    text-align: justify;
    font-weight: 550;
}
.evolution-txdis-heading{
    color: #070F5A;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 800;
    margin-top: 1rem;
}
#texasDisclosureContainer a {
    text-decoration: none;
    color: #070F5A;
    text-decoration: underline;
}